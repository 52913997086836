html{
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Proxima Nova, sans-serif;
}
body{
    background-color: #0c0c1d;
    color: rgb(235, 235, 235);
    /* #001524; */
    /* 0c0c1d */
    /* 00080d */
}